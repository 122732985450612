import { ApplicationUpdateRequest } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';
import { convertToFloat } from '~/utils/numbers';
import { statesAndProvincesMap } from '../countries';

export class UpdateRentalApplicationInfoUseCase extends MagicUseCase {
  protected async runLogic(request: ApplicationUpdateRequest) {
    const application = this.getState().user.rentalApplication.application;
    if (request.firstName !== undefined) {
      application.firstName = request.firstName;
    }
    if (request.lastName !== undefined) {
      application.lastName = request.lastName;
    }
    if (request.email !== undefined && this.getState().user.rentalApplication.application) {
      application.email = request.email;
    }
    if (request.phone !== undefined) {
      application.phone = request.phone;
    }
    if (request.ssn !== undefined) {
      application.ssn = request.ssn;
    }
    if (request.dateOfBirth !== undefined) {
      application.dateOfBirth = request.dateOfBirth;
    }
    if (request.desiredMoveInDate !== undefined) {
      application.desiredMoveInDate = request.desiredMoveInDate;
    }
    if (request.interestedUnitIds !== undefined) {
      application.interestedUnits = request.interestedUnitIds;
    }
    if (request.comments?.length) {
      application.comments = request.comments;
    }
    if (request.history !== undefined) {
      application.residentialHistory = request.history.map((history) => {
        const selectedCountry = history.selectedCounty || 'USA';
        const states = (statesAndProvincesMap as Record<string, { abbreviations: string[] }>)[selectedCountry]?.abbreviations;
        let selectedState = history.selectedState;
        const isPresentStateInCountry = selectedState && states?.includes(selectedState);
        if (!isPresentStateInCountry) {
          selectedState = states?.[0];
        }
        const address = history.address;
        if (address) {
          address.state = selectedState || '';
          address.country = selectedCountry;
        }
        return {
          rent: convertToFloat(history.rent),
          address,
          landlordName: history.landlordName,
          landlordPhone: history.landlordPhone,
          moveInDate: history.moveInDate,
          moveOutDate: history.moveOutDate,
          reasonForLeaving: history.reasonForLeaving,
        };
      });
    }
    if (request.employmentHistory !== undefined) {
      application.employmentHistory = request.employmentHistory.map((history) => {
        return {
          name: history.name,
          phone: history.phone,
          position: history.position,
          salary: convertToFloat(history.salary),
          startDate: history.startDate,
          endDate: history.endDate,
        };
      });
    }
    if (request.files !== undefined) {
      application.newFiles = request.files;
    }
    if (request.annualIncome !== undefined) {
      application.annualIncome = convertToFloat(request.annualIncome);
    }
    if (request.questions !== undefined) {
      application.questionsAndAnswers = request.questions;
    }
    if (request.hasAcceptedMagicDoorTerms !== undefined) {
      application.hasAcceptedMagicDoorTerms = request.hasAcceptedMagicDoorTerms;
    }
    if (request.hasAcceptedTransUnionTerms !== undefined) {
      application.hasAcceptedTransUnionTerms = request.hasAcceptedTransUnionTerms;
    }
  }
}
