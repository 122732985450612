export const convertToFloat = (stringNum?: string): number | undefined => {
  if (!stringNum) {
    return undefined;
  }
  const numberString = convertToNumberString(stringNum);
  const result = parseFloat(numberString);
  return isNaN(result) ? undefined : result;
};

const convertToNumberString = (stringNum?: string): string => {
  if (!stringNum) {
    return '';
  }
  return stringNum.replace(/[^0-9.-]+/g, '');
};
